import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function PaymentPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { journeyId, journeyData } = state || {};

  const handleCheckout = async () => {
    if (!journeyData || !journeyId) {
      alert('No journey data available.');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ journeyId, journeyData }),
        }
      );

      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }

      const { id: sessionId } = await response.json();

      // Redirect to Stripe Checkout
      const stripe = await window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error initiating Stripe Checkout:', error);
      alert('Error initiating payment. Please try again.');
    }
  };

  if (!journeyData || !journeyId) {
    return <p>No journey data available. I have no idea how you found this. Send me a Whatsapp.</p>;
  }

  return (
    <div style={{ textAlign: 'center', padding: '1px' }}>
      {/* Total Box */}
      <div
        style={{
          backgroundColor: '#f8f9fa',
          padding: '10px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          marginBottom: '20px',
          display: 'inline-block',
          fontWeight: 'bold',
          color: '#853a38'
        }}
      >
        <p style={{ margin: '0' }}>Pay With Stripe to Activate Code</p>
      </div>

      {/* Button Container */}
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
        <button
          onClick={() => navigate('/')}
          style={{
            padding: '10px 20px',
            backgroundColor: '#853a38',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          Back
        </button>

        <button
          onClick={handleCheckout}
          style={{
            padding: '10px 20px',
            backgroundColor: 'white',
            color: '#853a38',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          Pay
        </button>
      </div>
    </div>
  );
}

export default PaymentPage;
