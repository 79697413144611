// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CodeLogin from './CodeLogin';
import JourneyPage from './components/JourneyPage';
import FetchData from './components/FetchData';
import AdminJourneyPage from './AdminJourneyPage';
import PaymentPage from './PaymentPage';
import Header from './components/Header';
import PaymentCancelled from './PaymentCancelled';

const stripePromise = loadStripe('your-stripe-publishable-key'); // Replace with your Stripe publishable key

function App() {
  const [journeyData, setJourneyData] = useState(null);
  const [journeyId, setJourneyId] = useState(null);

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <div className="App">
          <Header /> {/* Display Header on all pages */}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <CodeLogin
                    onAccess={(data, id) => {
                      setJourneyData(data);
                      setJourneyId(id);
                    }}
                  />
                </>
              }
            />
            <Route
              path="/journey"
              element={
                journeyData ? (
                  <JourneyPage journeyData={journeyData} journeyId={journeyId} />
                ) : (
                  <div>Please log in with a valid code to access the journey page.</div>
                )
              }
            />
            <Route path="/fetch-data" element={<FetchData />} />
            <Route path="/admin" element={<AdminJourneyPage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/payment-cancelled" element={<PaymentCancelled />} /> {/* Payment cancelled route */}
          </Routes>
        </div>
      </Router>
    </Elements>
  );
}

export default App;
