// src/FetchData.js
import React, { useEffect, useState } from 'react';

function FetchData() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('http://localhost:5000/api/test-firestore');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        console.log("Fetched data:", result.data); // Log fetched data to check structure
        setData(result.data); // Assuming data is an array of documents
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Firestore Data</h2>
      <ul>
        {data.map((doc, index) => (
          <li key={index}>
            {Object.entries(doc).map(([key, value]) => (
              <p key={key}><strong>{key}:</strong> {value}</p>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FetchData;
