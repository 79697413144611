// src/components/JourneyPage.js
import React, { useEffect, useState } from 'react';
import Journey from '../Journey';
import { useLocation } from 'react-router-dom';

function JourneyPage({ journeyData: propJourneyData, journeyId: propJourneyId }) {
  const location = useLocation();
  const [journeyData, setJourneyData] = useState(propJourneyData);
  const [journeyId, setJourneyId] = useState(propJourneyId);
  const [routeNames, setRouteNames] = useState([]); // State for route names

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');

    if (sessionId) {
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/checkout-session?sessionId=${sessionId}`
      )
        .then((res) => res.json())
        .then(async (session) => {
          const journeyIdFromSession = session.metadata.journeyId;
          setJourneyId(journeyIdFromSession);

          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/journey/${journeyIdFromSession}`
            );
            if (!response.ok) {
              throw new Error(`Server error: ${response.statusText}`);
            }
            const data = await response.json();
            setJourneyData(data);
          } catch (error) {
            console.error('Error fetching journey data:', error);
          }
        })
        .catch((error) => {
          console.error('Error fetching session data:', error);
        });
    }
  }, [location.search]);

  if (!journeyData || !journeyId) {
    return <p>Loading journey details...</p>;
  }

  return (
    <div style={{ padding: '10px' }}>
      <h2>Route: {routeNames.join(', ')}</h2>
      <Journey
        journeyData={journeyData}
        journeyId={journeyId}
        setRouteNames={setRouteNames} // Pass setRouteNames to Journey
      />
    </div>
  );
}

export default JourneyPage;
