import React from 'react';
import { useNavigate } from 'react-router-dom';

function PaymentCancelled() {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div
        style={{
          backgroundColor: '#f8f9fa',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          display: 'inline-block',
          marginBottom: '20px',
          maxWidth: '600px',
          fontWeight: 'bold',
          color: '#853a38'
        }}
      >
        <p style={{ margin: '0', fontSize: '18px' }}>
          You have cancelled your payment. Let me know if you have any questions or you believe this to be in error.
        </p>
      </div>

      <button
        onClick={() => navigate('/')}
        style={{
          padding: '10px 20px',
          backgroundColor: '#853a38',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontWeight: 'bold',
          marginTop: '10px',
        }}
      >
        Go to Homepage
      </button>
    </div>
  );
}

export default PaymentCancelled;
